<template>
  <table class="users-table table is-fullwidth">
    <thead>
      <tr>
        <th
          v-for="header in [
            $t('users.form.name'),
            $t('users.form.email'),
            $t('users.form.mobileNumber'),
            $t('users.form.role'),
            $t('users.form.hubs')
          ]"
          :key="`page_table_header_${header}`">
          {{ header }}
        </th>
        <th/>
      </tr>
    </thead>
    <tbody>
      <tr v-if="users.length === 0">
        <td
          colspan="99"
          class="has-text-centered">
          {{ $t('users.table.empty') }}
        </td>
      </tr>
      <tr
        v-for="user in users"
        :key="`users_table_user_${user.email}`">
        <td>
          {{ user.name }}
        </td>
        <td>
          {{ user.email }}
        </td>
        <td>
          <template v-if="!isSystemAccount(user)">
            {{ user.mobileNumber }}
          </template>
        </td>
        <td>
          {{ user.role }}
        </td>
        <td>
          {{ formatHubs(user.authorizedHubIds) }}
        </td>
        <td>
          <a @click="editUser(user)">
            {{ $t('common.actions.edit') }}
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { isSystemAccount } from '@js/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'users-table',
  props: {
    users: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('hub', [
      'getHubShortCode'
    ])
  },
  methods: {
    isSystemAccount(user) {
      return isSystemAccount(user)
    },
    formatHubs(hubIds) {
      const hubShortCodes = hubIds.map(this.getHubShortCode)
      return hubShortCodes.join(', ')
    },
    editUser(user) {
      this.$emit('edit', user)
    }
  }
}
</script>

<style lang="scss" scoped>
.users-table {
  margin-bottom: $space-xl;
}
</style>
